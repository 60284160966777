import { transparentize } from 'polished';
import React, { useMemo, useState } from 'react';
import styled from 'styled-components';

import Icon from '@shared/ui/sdk/components/Icon';
import { COLORS } from '@shared/ui/sdk/utils/constants';

import config from '../../utils/config';
import AuthTextInput, { Props as AuthTextInputProps } from '../AuthTextInput';

const passwordStrengthRegEx: RegExp = new RegExp(
  '(?<upperCase>[A-Z])|(?<lowerCase>[a-z])|(?<symbol>[()*@#$%^&+!=?>_+{}/.,<":;\'-])',
  'g',
);

const PasswordInputWrapper = styled.div`
  position: relative;
`;

const PasswordRequirementContainer = styled.div<{ active: boolean }>`
  padding: 10px;
  position: absolute;
  width: calc(100% + 20px);
  z-index: 2;
  top: calc(100% + 4px);
  left: -10px;
  background: ${COLORS.WHITE};
  border: 1px solid ${COLORS.BORDER};
  box-shadow: 0px 10px 15px 0px ${transparentize(0.2, COLORS.BOX_SHADOW)};
  border-radius: 3px;
  overflow: hidden;
  transition: 0.1s ease-in-out all;
  opacity: 0;
  transform: translateY(-10px) scaleY(0);
  transform-origin: top;

  ${({ active }) =>
    active &&
    `    
    opacity: 1;
    transform: translateY(0px) scaleY(1);
  `}
`;

const PasswordRequirementItem = styled.div`
  display: inline-flex;
  width: 50%;
  line-height: 1;
  padding: 4px 0;
`;

const PasswordRequirementItemLabel = styled.span`
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
  color: ${COLORS.GREY};
  margin-left: 7px;
`;

const PasswordRequirementIndicator = styled(Icon)`
  width: 16px;
  height: 16px;
  position: absolute;
  right: 0;
  top: 50%;
  background: ${COLORS.WHITE};
`;

type Props = Omit<AuthTextInputProps, 'type'>;

type PasswordGroup = {
  length: boolean;
  lowerCase: string;
  symbol: string;
  upperCase: string;
};

const ValidationKeyLabelMap: { label: string; key: keyof PasswordGroup }[] = [
  { label: 'At least 8 characters', key: 'length' },
  { label: 'At least 1 lowercase letter', key: 'lowerCase' },
  { label: 'At least 1 uppercase letter', key: 'upperCase' },
  { label: 'At least 1 symbol', key: 'symbol' },
];

export const PasswordInput: React.FC<Props> = ({ value, onChange, ...props }) => {
  const { PASSWORD_VALIDATION_ENABLED } = config;
  const [password, setPassword] = useState<string>('');
  const [isInputFocused, setIsInputFocused] = useState<boolean>(false);

  const passwordStrength = useMemo<PasswordGroup>(() => {
    let groups: PasswordGroup = {
      length: false,
      lowerCase: '',
      symbol: '',
      upperCase: '',
    };
    let regexMatch: RegExpExecArray | null;

    while ((regexMatch = passwordStrengthRegEx.exec(password)) !== null) {
      // @ts-ignore key type conflict with PasswordGroup
      groups = Object.fromEntries(
        Object.entries(regexMatch.groups!).map(([key, value]) => {
          return [key, `${groups[key]}${value || ''}`];
        }),
      );
    }

    return { ...groups, length: password.length >= 8 };
  }, [password]);

  const isValidPassword: Boolean = !Object.values(passwordStrength).some((value) => !value);

  return (
    <PasswordInputWrapper>
      <AuthTextInput
        value={value}
        type="password"
        onFocus={() => setIsInputFocused(true)}
        onBlur={() => setIsInputFocused(false)}
        onChange={(updatedValue: string) => {
          setPassword(updatedValue);
          onChange(updatedValue);
        }}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            setIsInputFocused(false);
          }
        }}
        {...props}
      />
      {PASSWORD_VALIDATION_ENABLED && (
        <>
          <PasswordRequirementContainer active={isInputFocused && !isValidPassword}>
            {ValidationKeyLabelMap.map(({ label, key }) => (
              <PasswordRequirementItem key={key}>
                <Icon
                  name={!passwordStrength[key] ? 'inactive' : 'success'}
                  width={16}
                  height={16}
                />
                <PasswordRequirementItemLabel>{label}</PasswordRequirementItemLabel>
              </PasswordRequirementItem>
            ))}
          </PasswordRequirementContainer>
          {((isValidPassword && isInputFocused) || (password && !isInputFocused)) && (
            <PasswordRequirementIndicator name={isValidPassword ? 'success' : 'error'} />
          )}
        </>
      )}
    </PasswordInputWrapper>
  );
};
