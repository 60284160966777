import React from 'react';
import styled from 'styled-components';

import TextInput, { TextInputElement, TextInputRoot } from '@shared/ui/sdk/components/TextInput';
import { COLORS } from '@shared/ui/sdk/utils/constants';

// TODO extend HTMLProps<HTMLInputElement> - currently causes a type violation with Styled Components
export type Props = {
  disabled?: boolean;
  label: string;
  onChange: (value: string) => void;
  placeholder: string;
  type: string;
  value: string;
  required?: boolean;
  onFocus?: () => void;
  onBlur?: () => void;
  onKeyDown?: (event: React.KeyboardEvent) => void;
};

const AuthTextInputRoot = styled.label`
  display: flex;
  flex-direction: column;

  // Normally we'd use a styled(TextInput) wrapper instead, but for some reason those styles were
  // getting applied to TextInput's inner <input> element, rather than its wrapper <div>, which has
  // the border.
  ${TextInputRoot} {
    border: none;
    border-bottom: 1.5px solid #c9cfd6;
  }
`;

const AuthTextInputLabelContents = styled.span`
  display: block;
  font-weight: 500;
  font-size: 12px;
  color: ${COLORS.GREY};
  margin-bottom: 7px;
`;

const AuthTextInputTextInputElement = styled(TextInputElement)`
  padding: 8px 0;
  font-size: 14px;
`;

const AuthTextInput: React.FC<Props> = (props: Props) => {
  const { label, ...inputProps } = props;

  return (
    <AuthTextInputRoot>
      <AuthTextInputLabelContents>{label}</AuthTextInputLabelContents>
      <TextInput {...inputProps} TextInputElementComponent={AuthTextInputTextInputElement} />
    </AuthTextInputRoot>
  );
};

export default AuthTextInput;
