import Link from 'next/link';
import React from 'react';
import styled from 'styled-components';

type Props = {};

const AuthHeaderRoot = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  padding: 26px 40px;
`;

const AuthHeader: React.FC<Props> = () => {
  return (
    <AuthHeaderRoot>
      <Link href="/">
        <a href="/">
          <img src="/images/paragon-logo-dark.svg" alt="Paragon logo" />
        </a>
      </Link>
    </AuthHeaderRoot>
  );
};

export default AuthHeader;
