import React from 'react';
import styled from 'styled-components';

import ActivityIndicator from '@shared/ui/sdk/components/ActivityIndicator';
import Button, { ButtonIcon } from '@shared/ui/sdk/components/Button';
import { COLORS } from '@shared/ui/sdk/utils/constants';

type Props = {
  id?: string;
  title: React.ReactNode;
  subtitle: React.ReactNode;
  children: React.ReactNode;
  submitLabel: string;
  loading: boolean;
  error?: string;
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => any;
};

export const AuthFormRoot = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;
  padding: 15px;
`;

export const AuthFormTitle = styled.span`
  display: block;
  font-size: 24px;
  font-weight: 600;
  line-height: 1.4;
`;

export const AuthFormSubtitle = styled.span`
  font-weight: 500;
  margin-top: 20px;
  display: block;
  font-size: 13px;
  color: ${COLORS.GREY};
  line-height: 20px;
`;

const AuthFormContents = styled.form`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-top: 30px;
  > * {
    margin-top: 20px;
  }
`;

const AuthFormSubmit = styled(Button).attrs({ 'data-testid': 'auth-form-submit' })`
  position: relative;
  display: flex;
  justify-content: center;
  align-self: center;
  font-size: 14px;
  width: 100%;
  height: 36px;
  margin-top: 30px;
  border-radius: 3px;
`;

const AuthFormSubmitIcon = styled(ButtonIcon)`
  position: absolute;
  left: 12px;
`;

const ActivityIndicatorWrapper = () => <ActivityIndicator />;

const AuthFormSubmitIconWrapper = () => (
  <AuthFormSubmitIcon as={ActivityIndicatorWrapper} hasTitle={true} />
);

const AuthFormError = styled.span`
  font-weight: 500;
  font-size: 12px;
  color: #fb6767;
  text-align: center;
  margin-top: 15px;
`;

const AuthForm: React.FC<Props> = (props: Props) => {
  const icon = props.loading ? 'activity' : undefined;

  return (
    <AuthFormRoot>
      <AuthFormTitle>{props.title}</AuthFormTitle>
      <AuthFormSubtitle>{props.subtitle}</AuthFormSubtitle>
      <AuthFormContents id={props.id} onSubmit={props.onSubmit}>
        {props.children}

        <AuthFormSubmit
          disabled={props.loading}
          color={COLORS.PRIMARY}
          title={props.submitLabel}
          type="submit"
          icon={icon}
          IconComponent={AuthFormSubmitIconWrapper}
        />
      </AuthFormContents>

      {props.error && (
        // tslint:disable-next-line:react-no-dangerous-html
        <AuthFormError
          data-testid="auth-form-error"
          dangerouslySetInnerHTML={{ __html: props.error }}
        ></AuthFormError>
      )}
    </AuthFormRoot>
  );
};

export default AuthForm;
