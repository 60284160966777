import React from 'react';
import styled from 'styled-components';

import { COLORS } from '@shared/ui/sdk/utils/constants';

type Props = {
  children: React.ReactNode;

  /**
   * An image source path, i.e. /images/xyz.jpg
   */
  customerImage: string;
  customerName: string;
  customerRole: React.ReactNode;
  /**
   * An image source path
   */
  companyImage: string;
};

const Card = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  background-color: white;
  max-width: 500px;
  border: 0.5px solid ${COLORS.BORDER};
  border-radius: 3px;
  padding: 30px 30px 30px 0px;
  box-shadow: 0px 5px 10px ${COLORS.BOX_SHADOW_HARD};

  font-family: 'IBM Plex Sans', Inter, sans-serif;
  font-size: 14px;
  line-height: 23.8px;
  color: ${COLORS.GREY};

  p {
    line-height: 23.8px;
  }

  strong {
    font-weight: 600;
    color: ${COLORS.TEXT};
  }
`;

const CustomerImage = styled.img`
  position: relative;
  left: -20px;
  border-radius: 3px;
  box-shadow: 0px 10px 15px ${COLORS.BOX_SHADOW_HARD};
  width: 80px;
  height: 80px;
`;

const CustomerBio = styled.div`
  font-size: 14px;
  p {
    margin: 0;
    line-height: 23.5px;
  }
`;

const CustomerName = styled.p`
  font-weight: 600;
  color: ${COLORS.BLACK};
`;
const CustomerInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Logo = styled.img`
  height: 100%;
`;

const TestimonialCard: React.FC<Props> = ({
  children,
  companyImage,
  customerImage,
  customerName,
  customerRole,
}: Props) => {
  return (
    <Card>
      <CustomerImage src={customerImage} />
      <CustomerInfo>
        <Logo src={companyImage} />
        {children}
        <CustomerBio>
          <CustomerName>{customerName}</CustomerName>
          {customerRole}
        </CustomerBio>
      </CustomerInfo>
    </Card>
  );
};

export default TestimonialCard;
