import { NextPage } from 'next';
import { transparentize } from 'polished';
import React from 'react';
import styled from 'styled-components';

import { COLORS } from '@shared/ui/sdk/utils/constants';

import AuthHeader from '../components/AuthHeader';

import TestimonialCard from './TestimonialCard';

type Props = {
  children: (string | JSX.Element) | (string | JSX.Element)[];
  hideSplitPaneView?: boolean;
};

export const SplitView = styled.div`
  height: 100%;
  display: flex;
  font-family: Inter, sans-serif;
`;

export const SplitViewRight = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  height: 100%;
  box-shadow: 0 10px 15px ${COLORS.BOX_SHADOW_HARD};
`;

export const SplitViewLeft = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 100%;
  padding: 15px;
  background-color: ${transparentize(0.85, COLORS.BORDER)};

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

const ReviewText = styled.p`
  margin: 18px 0px;
`;

const AuthContainer: NextPage<Props> = ({ children, hideSplitPaneView = false }: Props) => (
  <SplitView>
    <AuthHeader />
    {!hideSplitPaneView && (
      <SplitViewLeft>
        <TestimonialCard
          customerName="Rudy Lai"
          customerImage="/images/rudy-lai.png"
          customerRole={<p>Co-Founder of QuantCopy</p>}
          companyImage="/images/quantcopy.svg"
        >
          <ReviewText>
            <strong>
              Paragon not only saved us weeks of engineering time delivering a Salesforce
              integration for our customers,
            </strong>{' '}
            we can now consistently ship new integrations on our roadmap.
          </ReviewText>
        </TestimonialCard>
      </SplitViewLeft>
    )}
    <SplitViewRight>{children}</SplitViewRight>
  </SplitView>
);

export default AuthContainer;
