import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { COLORS, inputFocus } from '../utils/constants';

import Icon from './Icon';

// TODO extend HTMLProps<HTMLInputElement> - currently causes a type violation with
// Styled Components
export type TextInputProps = {
  className?: string;
  disabled?: boolean;
  leftItem?: React.ReactNode;
  max?: string;
  min?: string;
  onBlur?: (event: React.FocusEvent) => void;
  onChange: (value: string) => void;
  onFocus?: () => void;
  onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onSave?: () => void;
  onClear?: () => void;
  pattern?: string;
  placeholder?: string;
  ref?: React.Ref<HTMLInputElement>;
  required?: boolean;
  rightItem?: React.ReactNode;
  size?: number;
  spellcheck?: boolean;
  step?: string;
  style?: React.CSSProperties;
  TextInputElementComponent?: React.ElementType;
  trackValidity?: boolean;
  type?: string;
  useFocusGlow?: boolean;
  errorMessage?: string | null;
  validator?: (value: string) => boolean;
  value: string;
  autoFocus?: boolean;
};

type TextInputRootProps = {
  valid: boolean;
  errorMessage?: string | null;
  showClear?: boolean;
};

export const HORIZONTAL_PADDING = 10;

const ClearWrapper = styled.div<{ show: boolean }>`
  position: absolute;
  right: 10px;
  bottom: calc(50% - 5px);
  width: 10px;
  height: 10px;
  display: ${(props) => (props.show ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const TextInputRoot = styled.div<TextInputRootProps>`
  display: flex;
  position: relative;
  height: 28px;
  border: 1px solid
    ${(props: TextInputRootProps) => (props.valid ? COLORS.BORDER : COLORS.INVALID_HARD)};
  background-color: ${(props: TextInputRootProps) =>
    props.valid ? 'initial' : COLORS.INVALID_SOFT};
  border-radius: 3px;

  ${(props: TextInputRootProps) =>
    props.errorMessage &&
    `
      position: relative;
      margin-bottom: 15px;
      border-color: ${COLORS.DANGER}
    `}
  &:hover {
    ${ClearWrapper} {
      display: ${(props) => (props.showClear ? 'flex' : 'none')};
    }
  }
`;

const TextInputLeftItem = styled.div`
  display: flex;
`;

const TextInputRightItem = styled.div`
  display: inline;
  align-self: center;
`;

export const TextInputElement = styled.input<TextInputProps>`
  font-family: Inter, sans-serif;
  flex: 1;
  width: 100%;
  padding: 0 ${HORIZONTAL_PADDING}px;
  border: 0;
  outline: 0;
  background-color: transparent;
  color: ${COLORS.TEXT};
  font-weight: 500;

  &::placeholder {
    color: ${COLORS.PLACEHOLDER};
  }

  &[type='number']::-webkit-inner-spin-button,
  &[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &:focus {
    ${(props: TextInputProps) => (props.useFocusGlow ? inputFocus : '')}
  }
`;

const ErrorMessage = styled.div`
  display: flex;
  align-items: center;
  color: ${COLORS.DANGER};
  font-size: 11px;
  position: absolute;
  bottom: -20px;

  & > span {
    margin-left: 5px;
  }
`;

const TextInput = React.forwardRef(
  (
    { TextInputElementComponent = TextInputElement, style = {}, ...props }: TextInputProps,
    forwardedRef: React.Ref<HTMLInputElement>,
  ) => {
    const [originalValue, setOriginalValue] = useState<string>(props.value);
    const [valid, setValid] = useState<boolean>(true);
    const [showClear, setShowClear] = useState<boolean>(false);
    const [isFocused, setIsFocused] = useState<boolean>(false);

    const elementRef = useRef<HTMLInputElement>(null);

    function handleChange(event: React.ChangeEvent<HTMLInputElement>): void {
      props.onChange(event.target.value);
      if (event.target.value == '') {
        setShowClear(false);
      }
    }

    function validate(): boolean {
      return (
        elementRef.current!.checkValidity() &&
        ((!props.required && props.value.length === 0) ||
          !props.validator ||
          props.validator(props.value))
      );
    }

    function handleKeyPress(event: React.KeyboardEvent<HTMLInputElement>): void {
      props.onKeyPress?.call(elementRef, event);
      if (props.trackValidity) {
        if (event.key === 'Enter') {
          const newValid = validate();
          setValid(newValid);
          if (newValid) {
            elementRef.current!.blur();
          }
        } else {
          setValid(true);
        }
      }
    }

    function handleFocus(): void {
      setIsFocused(true);
      setValid(true);
      setOriginalValue(props.value);
      if (props.onClear) {
        setShowClear(true);
      }
      if (props.onFocus) {
        props.onFocus();
      }
    }

    function handleBlur(event: React.FocusEvent): void {
      setTimeout(() => {
        setShowClear(false);
      }, 100);
      if (props.trackValidity) {
        if (validate()) {
          if (props.onSave) {
            props.onSave();
          }
        } else {
          props.onChange(originalValue);
          setValid(true);
        }
      }

      if (props.onClear) {
        setShowClear(true);
      }

      if (props.onBlur) {
        props.onBlur(event);
      }
    }

    useEffect(() => {
      if (forwardedRef) {
        if (typeof forwardedRef === 'function') {
          forwardedRef(elementRef.current);
        } else {
          // hack
          (forwardedRef as { current: HTMLInputElement | null }).current = elementRef.current;
        }
      }
    }, []);

    const { className, onSave, ...textInputProps } = props;
    return (
      <TextInputRoot
        className={props.className}
        valid={valid}
        style={style}
        errorMessage={textInputProps.errorMessage}
        showClear={showClear && props.onClear != undefined}
      >
        {props.leftItem && <TextInputLeftItem>{props.leftItem}</TextInputLeftItem>}
        <TextInputElementComponent
          ref={elementRef}
          type="text"
          {...textInputProps}
          onChange={handleChange}
          onKeyPress={handleKeyPress}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
        {props.rightItem && <TextInputRightItem>{props.rightItem}</TextInputRightItem>}
        {props.errorMessage && (
          <ErrorMessage data-testid="error-text-input">
            <Icon name="error-exclamation" height={15} />
            <span>{props.errorMessage}</span>
          </ErrorMessage>
        )}
        <ClearWrapper
          show={showClear && props.onClear != undefined}
          onClick={(e) => {
            e.stopPropagation();
            if (props.onClear) {
              props.onClear();
            }
            const node = elementRef.current;
            if (node && isFocused) {
              node.focus();
            }
            setShowClear(false);
            setOriginalValue('');
            props.onChange('');
          }}
        >
          <Icon name={'clear'} />
        </ClearWrapper>
      </TextInputRoot>
    );
  },
);

export default TextInput;
